import * as React from 'react';
import Layout from '../components/Layout';
import BlogView from '../modules/blog';
import Footer from '../components/Footer';
import { Helmet } from 'react-helmet';

const Blog = () => {
  return (
    <Layout>
      <Helmet>
        <meta name="description" content="Our insights on latest trends and engineering in software market" />
        <title>Blog and Resources from Zegates - Zegates International</title>
      </Helmet>
      <BlogView />
      <Footer />
    </Layout>
  );
};

export default Blog;
